<script setup lang="ts">
import { computed } from "vue"
import { useAuthStore } from "~/store/auth"
import { useConfigStore } from "~/store/config"
import USFlag from 'assets/images/flags/united-states.svg'
import FrenchFlag from 'assets/images/flags/france.svg'
import UserAvatarImage from 'assets/images/avatars/300-1.jpg'
import { User } from "~/types/auth"
import { UpdateUserRequest } from "~/types/auth/api"
import { Locale } from "~/types/util"
import { getColor } from "~/utils/helpers"

interface Props {
    user: User
}

const authStore = useAuthStore()
const configStore = useConfigStore()

const props = defineProps<Props>()

const { t } = useI18n()

const emit = defineEmits<{
    updateLocale: [payload: UpdateUserRequest]
}>()

const countries = {
    en: {
        flag: USFlag,
        name: t(`english`)
    },
    fr: {
        flag: FrenchFlag,
        name: t(`french`)
    }
}

const signOut = () => {
    authStore.logOut()
    configStore.resetLayoutConfig()
}

const setLang = (lang: string) => {
    const payload: UpdateUserRequest = {
        locale: lang as Locale
    }

    emit(`updateLocale`, payload)
}

const currentLanguage = computed(() => {
    return user.value.locale
})

const currentLanguageLocale = computed(() => {
    return countries[user.value.locale as keyof typeof countries]
})

const user = computed<User>(() => {
    return props.user
})
</script>

<template>
    <!--begin::Menu-->
    <div
        class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold py-4 fs-6 w-275px"
        data-kt-menu="true">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
            <div class="menu-content d-flex align-items-center px-3">
                <!--begin::Avatar-->
                <div class="symbol symbol-50px me-5">
                    <img
                        v-if="false"
                        alt="Logo"
                        :src="UserAvatarImage">

                    <span
                        v-else
                        :class="`bg-light-${getColor(user.firstName)} text-${getColor(user.firstName)}`"
                        class="symbol-label fs-6 fw-bold">
                        {{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0) }}
                    </span>
                </div>
                <!--end::Avatar-->

                <!--begin::Username-->
                <div class="d-flex flex-column">
                    <div class="fw-bold d-flex align-items-center fs-5">
                        {{ user.firstName }} {{ user.lastName }}
                        <span
                            v-if="user.admin"
                            class="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                            {{ $t(`admin`) }}
                        </span>
                        <span
                            v-else
                            class="badge badge-light-danger fw-bold fs-8 px-2 py-1 ms-2">
                            {{ $t(`subscriber`) }}
                        </span>
                    </div>
                    <a
                        href="#"
                        class="fw-semibold text-muted text-hover-primary fs-7">{{ user.email }}</a>
                </div>
                <!--end::Username-->
            </div>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu separator-->
        <div class="separator my-2"></div>
        <!--end::Menu separator-->

        <!--begin::Menu item-->
        <div class="menu-item px-5">
            <NuxtLink
                class="menu-link px-5"
                to="/profile">
                {{ $t(`profile`) }}
            </NuxtLink>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu separator-->
        <div class="separator my-2"></div>
        <!--end::Menu separator-->

        <!--begin::Menu item-->
        <div
            class="menu-item px-5"
            data-kt-menu-trigger="hover"
            data-kt-menu-placement="left-start"
            data-kt-menu-flip="center, top">
            <div
                class="menu-link px-5">
                <span class="menu-title position-relative">
                    {{ $t(`language`) }}
                    <span
                        class="symbol fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                        {{ currentLanguageLocale.name }}
                        <img
                            class="w-15px h-15px rounded-1 ms-2"
                            :src="currentLanguageLocale.flag"
                            alt="metronic">
                    </span>
                </span>
            </div>

            <!--begin::Menu sub-->
            <div class="menu-sub menu-sub-dropdown w-175px py-4">
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                    <a
                        href="#"
                        class="menu-link d-flex px-5"
                        :class="{ active: currentLanguage === 'en' }"
                        @click="setLang('en')">
                        <span class="symbol symbol-20px me-4">
                            <img
                                class="rounded-1"
                                :src="USFlag"
                                alt="metronic">
                        </span>
                        {{ $t(`english`) }}
                    </a>
                </div>
                <!--end::Menu item-->

                <!--begin::Menu item-->
                <div class="menu-item px-3">
                    <a
                        href="#"
                        class="menu-link d-flex px-5"
                        :class="{ active: currentLanguage === 'fr' }"
                        @click="setLang('fr')">
                        <span class="symbol symbol-20px me-4">
                            <img
                                class="rounded-1"
                                :src="FrenchFlag"
                                alt="metronic">
                        </span>
                        {{ $t(`french`) }}
                    </a>
                </div>
                <!--end::Menu item-->
            </div>
            <!--end::Menu sub-->
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-5 my-1">
            <a
                class="menu-link px-5"
                @click="signOut()"> {{ $t(`signOut`) }} </a>
        </div>
    <!--end::Menu item-->
    </div>
    <!--end::Menu-->
</template>
